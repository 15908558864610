<template>
  <section class="main-login">
    <div class="main-box flexed">
      <div class="login-logo-box">
        <img src="../../assets/images/logo_1080.png" alt="抖工科技">
      </div>
      <Card class="login-card" icon="log-in" :bordered="false">
        <span slot="title">
            重置密码
        </span>
        <div class="form-con">
          <Form ref="resetForm" :model="resetForm" :rules="resetRules">
            <FormItem prop="oldPassword" >
              <Input size="large" type="password" class="login_input" v-model="resetForm.oldPassword" placeholder="请输入旧密码">
              </Input>
            </FormItem>
            <!-- style="padding:20px 0" -->
            <FormItem prop="password" >
              <Input size="large" type="password" class="login_input" v-model="resetForm.password" placeholder="密码由字母、数字和特殊字符组合">
              </Input>
            </FormItem>
            <FormItem prop="passwordAgain">
              <Input size="large" type="password" class="login_input" v-model="resetForm.passwordAgain" placeholder="再次确认密码">
              </Input>
            </FormItem>
            <FormItem class="accept_protocol">
               <Checkbox v-model="checkbox" @on-change="resetCheck">我已阅读并同意《抖工服务协议》</Checkbox>
            </FormItem>
            <FormItem>
              <Button
                size="large"
                :disabled="checkTrue"
                :style="buttonStyle"
                class="button-login"
                @click="resetSubmit('resetForm')"
                type="primary"
                long>
                重置
              </Button>
            </FormItem>
          </Form>
        </div>
      </Card>
    </div>
  </section>
</template>

<script>
import md5 from "js-md5"
import Storage from "../../utils/localStorage"
import constants from "../../constants/constants"
  export default {
    data() {
      let validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入旧密码'));
      } else {
        if(!/(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,30}/.test(value))
         {
          // callback(new Error('要求密码最小长度位8位，且必须包含字母、数字和特殊字符（~!@#$%^&*-+/\\.）'));
          callback(new Error('要求密码最小长度位8位，且必须包含字母、数字和特殊字符'));

        }
        callback();
      }
    };
      return {
        // 按钮的样式
        buttonStyle:{
          color: '#fff'
        },
        // 按钮状态
        checkTrue: true,
        // 复选框状态
        checkbox: false,
        // 表单对象
        resetForm: {},
        userPhone: "",
        userData: {},
        // 表单验证
        resetRules: {
          oldPassword: [
            {required:true,message:'旧密码不能为空'},
            {type:"string",min:8,message:"密码不少于8位"},
            {type:"string",max:20,message:"密码不大于20位"},
            ],
          // oldPassword: [{ validator: validatePass, trigger: 'blur' }],
          password: [
            // {required:true,message:'密码不能为空'},
            // {type:"string",min:8,message:"密码不少于8位"},
            // {type:"string",max:20,message:"密码不大于20位"},
            { validator: validatePass, trigger: 'blur' }
            ],
          passwordAgain: [
            // {required:true,message:'确认密码不能为空'},
            // {type:"string",min:8,message:"密码不少于8位"},
            // {type:"string",max:20,message:"密码不大于20位"},
            { validator: validatePass, trigger: 'blur' }
            ],
        }
      }
    },
    created() {

    },
    mounted() {
      this.getUserInfo();
    },
    methods: {
      // 复选框状态切换
      resetCheck(){
        if(this.checkbox == true) {
          this.checkTrue = false;
        }
        else {
          this.checkTrue = true;
        }
      },
      // 重置密码跳转
      resetSubmit(name) {
        this.$refs[name].validate(valid => {
          if(valid) {
          if (this.resetForm.oldPassword=='') {
              this.$Message.error("请输入旧密码");
            } 
            else if (this.resetForm.password != this.resetForm.passwordAgain) {
              this.$Message.error("两次密码不一致");
            } else {
              // this.resetForm.phone = this.userPhone;
              let obj = {
                oldPassword:md5(this.resetForm.oldPassword),
                password: md5(this.resetForm.password),
                passwordAgain: md5(this.resetForm.passwordAgain),
                phone: this.userPhone
              }
              this.$axios.post(`${this.apiUrlCros}/sapi/v3/account/reset/password`,obj).then(res => {
                if (res.data.code == "200" || res.data.code == "0") {
                  this.$Message.success("密码重置成功，请使用新密码登录！");
                  // this.common.setAuth(res.data.resData);
                  this.common.removeAuth();
                  this.$router.push({ name: 'login' })
                } else {
                  this.$Message.error(res.data.message);
                }
              }).catch(err => {
                this.$Message.error(err.message);
              })
            }
          }
        });
      },
      async getUserInfo() {
        const storage = new Storage()
        const CONSTANTS = constants
        let token = storage.get(CONSTANTS.EOVA_AUTH);
        // this.common.getAuth();
        // this.userData = await this.common.getUserInfoSync();
        if (token) {
            this.userPhone = token.login_id;
        } else {
          // this.$Message.error("登录已过期,请重新登录");
          this.$router.push({name: 'login'});
        }
      },
      toProtocolPage (type) {
        this.common.toProtocolPage(type);
      },
    },
  }
</script>

<style lang="stylus">
.main-login
  .accept_protocol
    color #333
  
</style>
